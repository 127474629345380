/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Button } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Dokumenty"}>
        <Column className="pb--20 pt--20" name={"cb63pa10s4q"} style={{"backgroundColor":"rgba(67,126,173,1)"}}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--80" name={"2itm19nkvdz"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Školní řád"}>
              </Title>

              <Button className="btn-box" use={"file"} href={"https://cdn.swbpg.com/d/13139/skolni-rad-2021-web.pdf"} style={{"backgroundColor":"rgba(83,51,242,1)"}} content={"Kliknutím zde zobrazíte"} document={"a93296dcf30b4654a0c993e3bf823732"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--20 pt--20" name={"ezvd8ls32gh"} style={{"backgroundColor":"rgba(67,126,173,1)"}}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}